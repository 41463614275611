import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";
import Splide from "@splidejs/splide";

// Alpine.plugin(Toolkit);
Alpine.plugin(focus);
Alpine.plugin(collapse);

Alpine.store("mobileMenu", {
  isOpen: false,

  toggle() {
    this.isOpen = !this.isOpen;
  },

  close() {
    this.isOpen = false;
  },
});

window.Alpine = Alpine;

Alpine.start();

new Splide(".splide", {
  pagination: true,
  arrows: false,
}).mount();

document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener("click", function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute("href")).scrollIntoView({
      behavior: "smooth",
    });
  });
});
